.searchcon {
    background-color: #f2eded;
}

@media only screen and (max-width: 600px) {
    .search{  
        margin: 5px 0px;
    }

    .searchproducts {
        margin-bottom: 12px !important;
    }
    
    .search__item{
        display: flex;
        background: white;
        margin: 10px;
    }

    .search__price__items{
        display: block;
    }
    
    .product__search__info{
        padding-top: 20px;
        height: 100%;
    }
    
    .product__price{
        margin-top: 2px;
    }
    .product__rating{
       display: flex;
    }
    
    .img {
        max-height: 100px;
        max-width: 100px;
        min-height: 100px;
        min-width: 100px;
        object-fit: contain;
        margin-bottom: 2px;
     }
    
    
}
@media only screen and (min-width: 600px) {

    .search>.searchproducts {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 12px !important;
    }

    .search>.search_banner {
        padding-top: 1px;
    }
    
    .search__item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .product__search__info{
        color: rgb(1, 1, 19);
        font-size: 16px;
        font-weight: 12px;
    }
    
    
    .search__price__items{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    .product__price{
        display: flex;
    }

    .new_price{
        color: rgb(229, 53, 53);
    }

    .old_price{
        color: rgb(203, 184, 184);
        font-size: 12px;
        padding-left: 5px;
    }
    .product__rating{
       display: flex;
    }
    
    .img {
        max-height: 200px;
        max-width: 200px;
        min-height: 200px;
        min-width: 200px;
        object-fit: contain;
        margin-bottom: 10px;
     }
    
    
}
