.radio__group__form>div {
    font-weight: bold;
    /* width: 30%; */
}

/* .radio__group__form>.head {
    max-width: 40%;
} */

.head>small {
    white-space: nowrap;
}

.radio_breakpoint {
    padding: 0 !important;
}

.radio_breakpoint>div>label:hover, 
.radio_breakpoint>div>input[type='radio']:hover {
    cursor: pointer;
}

.radio_breakpoint>div {
    width: fit-content;
    display: flex;
    align-items: center;
}

.radio_breakpoint>div>label {
    font-size: 13px;
    padding-left: 2px;
    vertical-align: middle;
}

 

.radio_breakpoint>div>input[type='radio']{
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #555555;
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;
    vertical-align: middle;
}

.radio_breakpoint>div>input[type='radio']:checked{
    border: 2px solid #43d8ff;
    background-color: #43d8ff;
}

.radio_breakpoint>div>input[type='radio']:checked ~ label {
    color: #43d8ff;
  }

  .radio_breakpoint>div>input[type='radio']:disabled, .radio_breakpoint>div>input[type='radio']:disabled ~ label {
    color: gray;
    cursor: not-allowed;
  }
/* @media only screen and (min-width: 600px) {
    .radio__group__form>div {
        font-weight: bold;
        width: 30%;
    }
    
    .radio__group__form>.head {
        max-width: 40%;
    }

    .head>small {
        white-space: nowrap;
    }

    .radio_breakpoint {
        padding: 0 !important;
    }

    .radio_breakpoint>div {
        width: fit-content;
        display: flex;
        align-items: center;
    }

    .radio_breakpoint>div>label {
        font-size: 13px;
        padding-left: 2px;
        vertical-align: middle;
    }

    .radio_breakpoint>div>input[type='radio']{
        appearance: none;
        width: 15px;
        height: 15px;
        border: 2px solid #555555;
        border-radius: 50%;
        background-clip: content-box;
        padding: 3px;
        vertical-align: middle;
    }

    .radio_breakpoint>div>input[type='radio']:checked{
        border: 2px solid #43d8ff;
        background-color: #43d8ff;
    }

    .radio_breakpoint>div>input[type='radio']:checked ~ label {
        color: #43d8ff;
      }
} */




@media only screen and (max-width: 530px) {

    .radio__group__form {
        justify-content: space-between;
    }

    .radio__group__form>.head {
        max-width: 50%;
    }
    .radio__group__form>.head>small {
        font-weight: bold;
        white-space: normal;
    }
    
    .radio_breakpoint {
        padding: 0 !important;
        /* margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px; */
        width: 50%;
        
    }


    .radio_breakpoint>div>label {
        font-size: 14px;
        padding-left: 2px;
    }

    .radio_breakpoint>div>input[type='radio']{
        appearance: none;
        width: 14px;
        height: 14px;
        border: 2px solid #555555;
        border-radius: 50%;
        background-clip: content-box;
        padding: 3px;
    }

    .radio_breakpoint>div>input[type='radio']:checked{
        border: 2px solid #43d8ff;
        background-color: #43d8ff;
    }

    .radio_breakpoint>div>input[type='radio']:checked ~ label {
        color: #43d8ff;
      }
}


@media only screen and (max-width: 400px) {
    .radio__group__form>.head {
        /* max-width: 0; */
        width: 30%;
        margin: 0;
    }

    .radio__group__form>.head>small {
        font-weight: bold;
        margin-right: 0;
        /* font-size: 13px; */
    }

    .radio_breakpoint>div>label {
        font-size: 12px;
    }

    .review_delivery>h6 {
        font-size: 14px;
    }
}