@media screen and (max-width:365px) {
    .sq-title>h5 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }
}

@media screen and (max-width:479px) {
   .product_s {
    margin: 0;
   }

}

@media only screen and (max-width: 600px) {

    .product_s {
        display: grid;
        width: 100%;
        box-shadow: 0.5px 0px 5px lightgray;
        
    }
    
    .product_square{
        display: grid;
        grid-template-columns: 50% 50%;
        /* gap: 1px; */
        /* color: #000000c9; */
        background: white;
        text-align: center;
        min-width: 100%;
        max-height: 400px;
        /* min-width: 100px; */
        justify-content: center;
        align-items: center;
        /* background-color: rgb(244, 233, 233); */
        /* z-index: 1; */
        /* border: 1px solid #f4e9e9 !important; */
    }
    
    .product_square__item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 5px;
        /* background-color: white; */
        font-size: 15px;
    }

    /* .product_square__item>a {
        height: 90% !important;
    } */

    .img_square{
        width: 90%;
        height: 100%;
        padding: 5px 5px 0 5px;
    }

    .product_square__item>.p_cont {
        display: flex;
        justify-content: center;
        align-items:flex-end;
        width: 100%;
        margin-bottom: 0;
        text-align: center;
        vertical-align: bottom;
        overflow: hidden;

    }
    
    .p_cont>p {
        margin: 0;
        width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .product__info{
        height: 100%;
    }
    
     .addbasket{
         height: 30px;
         padding: 1;
         font-size: 6;
         background-color: #f0c14b;
         border: none;
         border-radius: 10px;
     }
     .sq-title {
        overflow-x: hidden;
     }
     .sq-title>h5 {
        font-size: 18px !important;
    }
}


@media only screen and (min-width: 600px) {
    .product_s {
        display: grid;
        width: 50%;
        max-width: 50%;
        box-shadow: 0.5px 0px 5px lightgray;
    }

    .product_square{
        display: grid;
        grid-template-columns: repeat(2, 50%);
        background: white;
        text-align: center;
        min-width: 100%;
        max-height: 400px;
        justify-content: center;
        align-items: center;
    }
    
    .product_square__item{
        width: 100%;
        height: 150px;
        /* padding: 4px; */
        /* background-color: white; */
        font-size: 13px;
    }
    
    /* .product_square__item>a {
        height: 100%;
        width: 100%;
    } */

    .product_square__item>.p_cont {
        display: flex;
        justify-content: center;
        align-items:flex-end;
        width: 100%;
        margin-bottom: 0;
        text-align: center;
        vertical-align: bottom;
        overflow: hidden;
    }

    .p_cont>p {
        margin: 0;
        width: fit-content;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }
    .product_square__info{
        height: 100%;
        margin-bottom: 15px;
    }
    .img_square{
        width: 90%;
        height: 90%;
        /* border: 1px solid lightgray; */
        /* background-color: gray !important; */
        /* padding: 5px 5px 0 5px; */
    }

    .product_s>div>h5 {
        font-size: 20px;
    }
    
}

.p_cont>p {
    font-size: 12px;
}

.product_s>div {
    height: fit-content;
    z-index: 0;
}

.product_s>div>h5 {
    /* border-bottom: 1px solid #f4e9e9; */
    font-weight: 700;
    width: 100%;
    margin-bottom: 0;
    line-height: 18px;
    letter-spacing: -0.1px;
    word-spacing: -1px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 4px;
    background-color: white;
}

.explore_link {
    display: flex;
    padding: 12px;
    padding-left: 4px;
    width: 100%;
    background-color: white;
}


.explore_link>a {
    text-decoration: none;
    /* color: #84704b; */
    color: #0dcaf0;
    font-size: 13px;
    width: 100%;
    padding: 4px;
}

.explore_link>a:hover {
    color: blue;
    text-decoration: underline;
}