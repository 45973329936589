.mobile__cat{
    display: flex;
    /* align-items: center; */
    /* justify-items: center; */
    justify-content: space-around;
    margin-top: 5px;
    margin-bottom: 5px;
    background: white;
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    overflow-x: scroll;
    width: 100%;
}

.mobile__cat::-webkit-scrollbar {
    display: none;
}

.link__image{
    width: 100%;
}

.catgr__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    padding: 4px;
    height: 100%;
    text-align: center;
}

.catt__img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
}