.banners-carousel > .react-multiple-carousel__arrow--left,
.banners-carousel > .react-multiple-carousel__arrow--right {
  z-index: 0 !important;
  margin-top: -190px;
}

.react-multi-carousel-list,
.react-multi-carousel-list > ul {
  z-index: 0 !important;
}

@media screen and (max-width: 1070px) {
  .banners-carousel > .react-multiple-carousel__arrow--left,
  .banners-carousel > .react-multiple-carousel__arrow--right {
    margin-top: -100px;
  }
}

@media screen and (max-width: 765px) {
  .banners-carousel > .react-multiple-carousel__arrow--left,
  .banners-carousel > .react-multiple-carousel__arrow--right {
    margin-top: -10px;
  }
}

@media screen and (max-width: 603px) {
  .banners-carousel > .react-multiple-carousel__arrow--left,
  .banners-carousel > .react-multiple-carousel__arrow--right {
    margin-top: 0px;
  }
}
