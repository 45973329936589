@media only screen and (max-width: 280px) {
  .cat_card_body > div > h6 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .category_products_container > .product_infos {
    padding: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .product_infos > h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .cat_card {
    display: flex;
    text-decoration: none;
  }

  .add__cat_con {
    overflow: hidden;
  }

  .add__cat_con > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px;
  }

  .cat_card_body > div {
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
  }
  .cat_price_con {
    width: 50%;
  }
  .cat_price_con > p {
    font-size: 14px;
  }

  .cat_add__cat {
    height: fit-content;
    border-radius: 5px;
    display: flex;
    /* z-index: 2; */
    background-color: #24eac3;
    border-color: #24eac3;
    color: black;
  }

  .cat_card_img_con > img {
    height: 130px;
    width: 130px;
  }

  .cat_card_body {
    height: 100%;
  }
}

@media screen and (max-width: 760px) {
  .category_sidebar {
    overflow-y: hidden;
  }

  .cat_card_body > div {
    color: black;
  }
}

@media only screen and (min-width: 600px) {
  .cat_con > div {
    height: 100%;
  }
  .category_products_container {
    min-height: 100%;
  }

  .category_products_container > .product_infos {
    /* margin-left: -8px;
        margin-right: -8px; */
    padding: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .category_products {
    height: 90%;
    margin: 0 !important;
    /* margin-left: 8px !important;
        margin-right: 8px !important; */
  }

  .product_infos > h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .category_sidebar {
    height: 100%;
    overflow-y: auto;
  }

  .category_sidebar > div > p {
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }
  .cat_card {
    /* display: block; */
    /* display: flex;
        flex-direction: column;
        align-items: center; */
    text-decoration: none;
    height: 100%;
    margin-bottom: 5px;
  }

  .cat_card_img_con:hover {
    -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.9)
      0.17s forwards;
    animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.9) 0.17s
      forwards;
  }

  .cat_card_img_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45%;
  }

  .cat_card_img_con > img {
    height: 100px;
  }

  .cat_card_body {
    display: flex;
    flex-direction: column;
    justify-content: first baseline;
    height: 55%;
  }

  .cat_card_body > div {
    height: 100%;
    color: black;
  }

  .cat_card_body > div:hover {
    color: #05bcc4 !important;
  }

  .cat_card_body > h6 {
    height: fit-content;
  }

  /* .add__cat_con {
        height: fit-content;
    } */

  .add__cat_con {
    overflow: hidden;
    height: fit-content;
    margin-top: 5px;
  }

  .add__cat_con > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px;
    padding-top: 5px;
  }

  .cat_add__cat {
    height: fit-content;
    display: flex;
    background-color: #84704b;
    border-radius: 5px;
    color: white;
    border-color: #84704b;
  }

  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.cat_card_body > div > h6 {
  text-transform: capitalize !important;
}

.cat_view__cat {
  height: fit-content;
  display: flex;
  background-color: #5ab5b0;
  border-color: #5ab5b0;
  color: white;
  border-radius: 5px;
}
