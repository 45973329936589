

.category_link {
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.category_link:hover {
    cursor: pointer;
    color: blue;
}



@media screen and (min-width:600px) {
    .content-overflow {
        height: 400px;
        width: 100%;
        overflow-y: scroll;
    }
}