@media only screen and (min-width: 600px){

    .paymt_suc_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* height: 100%; */
    }

    .paymt_suc_container>.paymt_suc {
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        width: 40%;
        align-self: center;
        /* border: 1px solid seagreen ; */
        border-radius: 10px;
        box-shadow: 1px 1px 8px lightgray;

    }

    /* , .paymt_suc_container, .paymt_suc_details, .paymt_suc_top, .suc_btn */

    .paymt_suc_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: green;
        margin-bottom: 25px;
        height: 150px;
    }

    .paymt_suc_top>div>img {
        /* box-shadow: 1px 1px 8px lightgray; */
        /* border: 1px solid seagreen; */
        border-radius: 80px;
        height: 80px;
        width: 80px;
    }


    .paymt_suc_top>h3 {
        font-size: 18px;
        font-weight: bold;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .paymt_suc_top>h1 {
        font-weight: bold;
        font-size: x-large;
    }

    .paymt_info_container {
        background-color: white;
        box-shadow: 3px 2px 5px lightgray;
        border-radius: 8px;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        /* height: 210px; */
    }

    .paymt_info_container>div>img {
        height: 70px;
        width: 70px;
        border-radius: 40px;
        border: 1px solid seagreen;
        padding: 5px;
        margin-top: -30px;
        box-shadow: 3px 1px 4px lightgray;
        background-color: white;
        margin-bottom: 8px;
    }
    .paymt_info {
        font-weight: bold;

        color: #0000009d;

    }
    .paymt_info>span, .paymt_suc_details>div>span{
        color: #808080b6;
        font-size: 14px;
        font-weight: normal;
    }

    .paymt_info_container>div>h2 {
        font-weight: bold;
        font-size: x-large;
    }

    .paymt_suc_details{
        margin-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
        height: 200px;
    }



    .paymt_suc_details>h3 {
        font-weight: bold;
        font-size: x-large;
        margin-bottom: 12px;
    }

    .paymt_suc_details>div{
        height: 70%;
        overflow-y: scroll;
    }

    /* .paymt_suc_details>div::-webkit-scrollbar {
        display: none;
    } */

    .paymt_suc_details>div>div{
        font-size: 14px;
        font-weight: bold;
        color: #0000009d;
    }

    .paymt_suc_details>div>div>span {
        color: #808080;
        font-size: 14px;
    }

}

@media only screen and (max-width: 600px){
    .paymt_suc_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-content: center;
        padding: 10px;
        /* background-color: lightgray; */
    }

    .paymt_suc_top {
        text-align: center;
        color: green;
        margin-bottom: 30px;
    }

    .paymt_suc_top>img {
        /* margin-left: -20px; */
        -webkit-animation: vibrate-1 0.3s linear infinite both;
        animation: vibrate-1 0.3s linear infinite both;
    }

    .paymt_suc_top>h3 {
        font-size: 18px;
        font-weight: bold;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif    ;
    }

    .paymt_suc_top>h1 {
        font-weight: bold;
        font-size: x-large;
    }

    .paymt_info_container {
        background-color: white;
        box-shadow: 3px 2px 5px lightgray;
        border-radius: 8px;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .paymt_info_container>div>img {
        height: 50px;
        width: 50px;
        border-radius: 30px;
        border: 1px solid seagreen;
        padding: 5px;
        margin-top: -30px;
        box-shadow: 3px 1px 4px lightgray;
        background-color: white;
        margin-bottom: 8px;
    }

    .paymt_info_container>div>h2 {
        font-weight: bold;
        font-size: large;
    }

    .paymt_info {
        font-size: 14px;
        color: #808080b6;
    }
    .paymt_info>span, .paymt_suc_details>div>span{
        color: #0000009d;
        font-weight: bold;
    }

    .paymt_suc_details{
        margin-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
        /* height: 50%; */
    }

    .paymt_suc_details>h3 {
        font-weight: bold;
        font-size: 18px;
    }

    .paymt_suc_details>div{
        font-size: 14px;
        color: #808080;
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }


}