.uploader {
    display: flex;
    height: fit-content;
    justify-content: space-between;
}

.caspian_uploader {
    background-color: #58eec675;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 130px;
    flex-direction: column;
}

.caspian_uploader > input[type='file'] {
    display: none;
}

.caspian_uploader > label {
    padding: 12px;
    font-size: 16px;
    text-align: center;
    width: 100%;
    height: 100%;
}

.caspian_uploader > label:hover {
    cursor: pointer;
    background-color: #d3d3d31d;
}

.preview {
    display: flex;
    width: 77%;
}

.previews {
    width: fit-content;
    /* display: flex; */
    /* max-width: 77%; */
}

.fileList {
    /* margin-left: 12px; */
    height: 105px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.fileList > p {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    height: 25px;
}

.fileList > img {
    width: 80%;
    height: 80%;
}

.fileList > .removeImage {
    background-color: #ffffff00;
    color: red;
    font-weight: bold;
    position: absolute;

}

.removeImage {
    position: absolute;
    background-image: url('../../../images/delete.png');
    background-repeat: no-repeat;
    background-size: contain;
    right: 10px;
    top: 0;
    background-color: white !important;
    height: 20px;
    padding: 0;
    width: 20px;
    text-align: center;
    border-radius: 20px;
}

.removeImage:hover {
    cursor: pointer;
}