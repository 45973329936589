.product-avail {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 40px;
  background-color: #d3d3d38e;
  margin: 0 12px;
}

.product-avail > span {
  font-size: larger;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;
  color: black;
}



@media only screen and (max-width: 460px) {
  .product__info > p {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 13px !important;
    display: grid;
    
  }

  .product-avail > span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .product {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 2px;
    max-height: 300px;
    min-width: 100px;
    margin: 5px 0px;
    background-color: white;
    /* z-index: 1; */
    justify-content: center;
    color: #000000c3;
  }

  .product__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product__item > div {
    display: flex;
    justify-content: center;
  }

  .produc_img {
    max-width: 50%;
    object-fit: contain;
  }

  .product__price__items {
    display: block;
    text-align: center;
  }

  .product__info {
    height: 100%;
    text-align: center;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product__info > p {
    /* letter-spacing: -1px; */
    font-weight: normal;
    text-transform: capitalize;
    font-size: 14px;
    margin: 0;
    height: 100%;
    width: 90%;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .product__price {
    margin-top: 2px;
  }
  .product__rating {
    display: flex;
  }

  .img {
    max-height: 100px;
    max-width: 100px;
    min-height: 100px;
    min-width: 100px;
    object-fit: contain;
    margin-bottom: 2px;
  }

  .old_price {
    position: relative;
    color: rgb(203, 184, 184);
    font-size: 13px;
    padding-left: 5px;
    bottom: 10px;
    text-decoration: line-through;
  }
  /* .react-multi-carousel-track {
        align-self: center;
    } */
}
@media only screen and (min-width: 600px) {
  .product {
    width: 100%;
    padding: 5px;
    max-height: 350px;
    min-width: 100px;
    margin: 5px;
    background-color: white;
    /* z-index: 1; */
  }

  .product__item {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100% !important;
  }

  .product__item > div {
    height: 80% !important;
    width: 80%;
  }
  .product__item > div > a {
    height: 100%;
    width: 100%;
  }
  .produc_img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    background-color: transparent;
    /* background-color: red; */
  }

  .product__info {
    color: rgb(1, 1, 19);
    font-size: 16px;
    font-weight: 12px;
    text-align: center;
    overflow: hidden;
    height: 10%;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product__info > p {
    letter-spacing: 0px;
    font-family: sans-serif;
    font-size: 15px;
    word-spacing: 1px;
    font-weight: 500 !important;
    margin: 0;
    height: 100%;
    width: 90%;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product__price__items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* height: 10%; */
  }

  .product__price {
    display: flex;
  }

  .new_price {
    color: rgb(229, 53, 53);
  }

  .old_price {
    position: relative;
    color: rgb(203, 184, 184);
    font-size: 13px;
    padding-left: 5px;
    bottom: 10px;
    text-decoration: line-through;
  }
  .product__rating {
    display: flex;
  }

  /* .img {
        max-height: 200px;
        width: 80%;
        min-height: 200px;
        object-fit: contain;
        margin-bottom: 10px;
     } */
}

/* .product__info {
    overflow: hidden;
}
 */




