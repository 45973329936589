.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.shipping {
  width: 100%;
  background-color: #ffffff !important;
}

.shipping_inner {
  width: 80%;
  justify-content: center;
}


.review {
  display: block;
  background-color: #ffffff;
  border: 1px solid lightgray;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 12px;
} 

.review_title_con>h5 {
  font-size: 17px;
}

.review_items_con {
  max-height: 14.6rem;
  overflow-y: auto;
}

.review_pro_image {
  width: 20%;
}

.review_pro_image>img {
  height: 100px;
}

.review_pro_details {
  width: 60%;
}

.review_pro_details>h6 {
  font-size: 18px;
  font-weight: bold;
}


.review_delivery_con {
  display: flex;
  justify-content: flex-end;
}

.review_delivery {
  border-top: 1px solid lightgray;
  padding: 8px;
  width: 80%;
}

.review_delivery>p {
  width: 35%;
  font-size: 14px;
}


.shipping-form{
  background-color: #ffffff;
  border: 1px solid lightgray;
}

.address__tittle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 8px;
  padding-left: 12px;
}

.cus__name{
  padding-left: 12px;
}

.order_summary {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid lightgray;
  margin-bottom: 12px;
}

.order_summary>h5 {
  font-weight: bold;
  font-size: 15px;
}

.order_summary_details {
  border-top: 1px solid lightgray;
  padding-top: 8px;
}

.order_summary_details>div {
  width: 100%;
}

.summary_title>h5 {
  font-size: 15px;
  white-space: nowrap;

}
.delivery, .itemtotal {
  width: 100%;
  justify-content: space-between;
}

.order_summary_total {
  border-top: 1px solid lightgray;
  padding-top: 8px;
}

.check{
  border: none;
  border-radius: 50%;
  padding: 5px;
  background-color: rgb(229, 220, 220);
  color: white;
}

.checkCompleted {
  background-color: #05bcc4;  
}

.pay {
  cursor: not-allowed;
  background-color: #05bcc4 !important;
  color: black;
}

.accordion {
  border: 1px solid lightgray ;
  margin-top: 8px;
}

.accordion-header {
  z-index: 0 !important;
  position: relative;

}

.accordion-button {
  font-family: sans-serif;
  font-size: 14px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.accordion-body {
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
  padding: 0 !important;
}

.accordion-body>textarea {
  height: 200px;
  outline: none;
  padding: 8px;
  resize: none;
  border: none;
}

.accordion-button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (min-width:1050px) {
  .order_sum_container {
    position: relative;
  }
}

@media screen and (max-width:1200px) {
  .review_pro_image {
    width: 30%;
  }

  .shipping_inner {
    width: 90%;
  }
}

@media screen and (max-width:1050px){
  .shipping_inner {
    width: 100%;
  }
}



@media only screen and (max-width: 600px) {

  .review_delivery {
    width: 100%;
  }
  .review_delivery>p {
    width: 60%;
    font-size: 14px;
  }
  .review_pro_details>h6 {
    font-size: 14px;
  }
}

@media screen and (max-width:470px) { 
  .removeprobtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .removeprobtn>svg {
    height: 100%;
  }

  .review_pro_details {
    width: 100%;
    justify-content: center !important;
  }

  .review_items_con>div {
    column-gap: 10px;
  }

  .review_pro_details>h6 {
    font-size: 13px;
  }
}

@media screen and (max-width:400px) {
  .review_pro_image {
    width: 40%;
  }
}


@media screen and (max-width:360px) {
  /* .review_pro_image>img {
    width: 60px;
  } */
  .review_pro_details {
    width: 50%;
  }

  .review_pro_details>strong {
    font-size: 14px;
  }

  .review_pro_details>.old_price {
    font-size: 11px !important;
  }
}

