    .cards {
        /* box-shadow: 2px 2px 5px lightgray; */
        background-color: rgb(255, 255, 255);
    }
    
.top_sells {
display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* background: #4ba6b6; */
    background-color: white;
    color: black;
}

/* .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: rgb(37, 37, 91);
    color: #43d8ff;
} */

.info {
    margin: 0;
    font-size: 22px ;
}