@media only screen and (min-width: 600px) {
    .aids_componenent{
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        max-height: 400px;
        max-width: 50%;
        margin: 10px;
        justify-content: center;
        align-items: center;
        background-color: white;
        z-index: 1;
    }
    
    .aids_img{
        max-width: 400px;
        max-height: 400px;
    }
}
@media only screen and (max-width: 600px) {
    .aids_componenent{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-height: 400px;
        min-width: 100px;
        justify-content: center;
        align-items: center;
        background-color: rgb(248, 244, 244);
        z-index: 1;
    }
    
    .aids_img{
        max-width: 400px;
        max-height: 400px;
    }
}