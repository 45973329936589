.payment__method{
  background-color: #ffffff;
  margin-top: 10px;
  padding-left: 10px;
  border: 1px solid lightgray;
  margin-bottom: 12px;
}

.confirmbtn{
  background: #f0c14b;
  border-radius: 5px;
  font-size: 20;
  font-weight: bold;
  padding: 10px;
  /* width: 96%; */
  border: none;
  color: white;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.payment__details{
  padding: 10px;
}

.hoz__rule{
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.payment__type__select{
  width: 35%;
  display: grid;
  grid-template-columns: 20px auto;
}
.input_con {
  width: 3%;
  padding-top: 5px;
}
.input_con>input {
  width: 17px;
  height: 17px;
}
.payment__type__details>h6 {
  font-weight: bold;
  margin: 0;
}

.payment__type__details>small {
  color: gray;
}

.payment_type_image {
  width: 50%;
  display: flex;
  justify-content: center;
}

.payment__tittle{
  width: 100%;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid lightgray;
}
.cus__address{
  font-size: 12px;
  color: rgb(162, 145, 145);
}

.checkCompleted{
  border: none;
    border-radius: 50%;
    background-color: #05bcc4;
    color: white;
}


#paystack:disabled, #cashondelivery:disabled ~ label {
  color: #f0c14b;
}

@media only screen and (max-width: 600px) {

  .payment__type__select {
    width: 100%;
  }

  .input_con {
    width: 4%;
    margin-right: 10px;
  }
  /* .payment__method{
      background-color: #ffffff;
      border: none;
      border-radius: 5px; 
      box-shadow: 2px 5px 1px #d3d3d3cb;
      margin-top: 10px;
    }
  
    .payment__tittle{
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
    }
  
    .confirmbtn{
      background: #f0c14b;
      border-radius: 5px;
      font-size: 20;
      font-weight: bold;
      padding: 10px;
      width: 96%;
      border: none;
      color: white;
      box-shadow: 2px 5px 1px #d3d3d3cb;
    }
  
    .payment__details{
      padding: 10px;
    }
  
    .hoz__rule{
      margin-top: 0em !important;
      margin-bottom: 0em !important;
    }
  
    .payment__type{
     padding-left: 10px;
    }
  
    .payment__type__select{
      display: flex;
      padding-left: 10px;
    }
  
    .payment__type__select > input{
      width: 40px;
    }
    .payment__tittle{
      display: grid;
      gap: 10px;
      padding: 10px;
      grid-template-columns: 20px auto;
    }
    .cus__address{
      font-size: 12px;
      color: rgb(162, 145, 145);
    }
  
    .check{
      border: none;
      border-radius: 50%;
      padding: 5px;
      background-color: rgb(229, 220, 220);
      color: white;
  }
  
    .checkCompleted{
      border: none;
        border-radius: 50%;
        background-color: #f0c14b;
        color: white;
    } */
  }
  
  /* @media only screen and (min-width: 600px) {
    .payment__method{
        background-color: #ffffff;
        border: none;
        border-radius: 5px;
        box-shadow: 2px 5px 1px #d3d3d3cb;
        margin-top: 10px;
        padding-left: 10px;
      }
  
      .confirmbtn{
        background: #f0c14b;
        border-radius: 5px;
        font-size: 20;
        font-weight: bold;
        padding: 10px;
        border: none;
        color: white;
      }
    
      .payment__details{
        padding: 10px;
      }
    
      .hoz__rule{
        margin-top: 0em !important;
        margin-bottom: 0em !important;
      }
    
      .payment__type__select{
        width: 35%;
        display: grid;
        grid-template-columns: 20px auto;
      }
      .payment__type__select>input {
        width: 20px;
      }
      .payment__tittle{
        width: 100%;
        display: flex;
      }
      .cus__address{
        font-size: 12px;
        color: rgb(162, 145, 145);
      }
    
      .checkCompleted{
        border: none;
          border-radius: 50%;
          background-color: #f0c14b;
          color: white;
      }
    } */