@media only screen and (min-width: 600px) {

.categories{
    background: rgb(244, 233, 233);
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.categories_group{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: white;
    z-index: 1;
}


.categories_group::-webkit-scrollbar {
    display: none;
}

.categories_item{
    margin-inline: 25px;
}

.vertical {
    color: rgb(225, 210, 210);
    height: 100%;
    margin-inline: 20;
}

.categories_item_img > img{
    width: 30px;
}

.cat__title{
    font-size: 14px;
    padding-inline: 10px;
    overflow-x: visible;
}
}



@media only screen and (max-width: 600px) {
    .categories{
        background: rgb(244, 233, 233);
    }
    
    .group_container {
        width: 70px;
        overflow-x: visible;
    }

    .categories_group{
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        overflow-x: scroll;
        overflow-y: hidden;
        background-color: white;
        z-index: 1;
    }
    
    
    .categories_group::-webkit-scrollbar {
        display: none;
    }

    .cat__title{
        font-size: 10px;
        text-decoration: none;
        overflow-x: auto;
    }
    
    .categories_item{
        padding-inline: 15px;
    }
    
    .vertical {
        color: rgb(225, 210, 210);
        height: 100%;
    }
    
    .categories_item_img > img{
        width: 35px;
    }
}

.categories {
    width: 100% !important;
    justify-content: center;
    
}

.categories>.row {
    margin-right: 0 !important;
    height: 100% !important;
}