.top-bar {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  background-color: #05bcc4;
  padding: 4px;
  overflow-x: scroll;
  margin: 0;
}

.top-bar::-webkit-scrollbar {
  display: none;
}

.sell-inner {
  display: flex;
  align-items: center;
  gap: 1px;
  color: #061a35;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
}

.sell-inner:hover {
  text-decoration: underline;
  color: white;
}

.top-bar-left {
  display: flex;
  align-self: center;
  /* justify-content: flex-end; */
  gap: 7px;
  font-weight: bold;
}

.top-bar-left > div {
  display: flex;
  align-items: center;
  gap: 2px;
}

.top-left-icons {
  padding-right: 8px;
  color: black;
}

.top-left-icons:hover {
  color: white;
}

.top-bar > div {
  overflow-x: scroll;
}

.top-bar-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.top-bar-inner {
  overflow-x: scroll;
}

.top-bar-inner::-webkit-scrollbar {
  display: none;
}

.top-bar-left > div > a {
  color: #061a35;
}

.top-bar-left > div > a:hover {
  text-decoration: underline;
  color: white;
}

@media screen and (max-width: 600px) {
  .top-bar-inner > a {
    text-decoration: none !important;
  }
}
@media screen and (max-width: 383px) {
  .sell-inner > span,
  .top-bar-left > div > a {
    font-size: 14px;
  }
  /* .top-bar-inner > a {
        width: 50%;
    } */
}
