.wrapper{
    display: flex;
    align-items: center;
    min-height: 80vh;
}

.sidebar{
    width: 20%;
    height: 80vh;
    background: rgb(27, 25, 25);
    padding: 10px;
}

.li-item{
    color: white;
}

.form{
    padding: 25px 50px;
}

.form header{
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
}

.form form{
    margin: 20px 0;
}

.form form .error-txt{
    color: #721c24;
    background: #f8d7da;
    padding: 8px 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
    display: none;
}


.form form .field{
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 10px;
}

.form form .field label{
    margin-bottom: 2px;
}

.form form .field input{
    outline: none;
}

.form form .input input{
    height: 40px;
    width: 100%;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form form .image input{
    font-size: 17px;
}

.form form .button input{
    margin-top: 13px;
    height: 45px;
    border: none;
    font-size: 17px;
    font-weight: 400;
    background: #333;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.form form .field i{
    position: absolute;
    right: 15px;
    color: #ccc;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
}

.form form .field i.active::before{
    color: #333;
    content: "\f070";
}

.form .link{
    text-align: center;
    margin: 10px 0;
    font-size: 17px;
}

.form .link a {
    color: #333;
}

.form .link a:hover{
    text-decoration: underline;
}

.filefield{
  font-family: calibri;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #BBB;
  background-color: #DDD;
  cursor: pointer;
}

.smtbtn{
  font-family: calibri;
  width: 150px;
  padding: 10px;
  color: white;
  border-radius: 5px;
  border: 1px solid #BBB;
  background-color: #fa5656;
  cursor: pointer;
}