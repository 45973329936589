/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

  body {
  background-color: rgb(234, 237, 237);
  margin: 0;
  font-family: 'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */

/* html,
body,
#root,
.app, */
.content {
  /* height: 100%; */
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}


.maintainance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintainance > img {
  object-fit: contain;
  width: 500px;
  height: 500px;
}

.maintainance > p {
  font-size: large;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .maintainance > img {
    width: 100%;
    height: 100%;
  }
}