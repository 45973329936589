

.orders-list {
    position: relative;
}

.order-img {
    height: 150px;
}


.order-menu {
    position: absolute !important;
    right: 0 !important;   
}

.order-menu {
    display: none;
}

.status-wrapper {
    padding-left: 4px;
    width: 50%;
    display: flex;
}

.status-wrapper > span {
    padding: 1px 4px;
    border-radius: 2px;
    color: white;
    margin: 0 4px;
    font-size: 13px;
}

.cancelled {
    background-color: crimson;
}

.received {
    background-color: #2dabc4;    
}

.shipped {
    background-color: #0000ffb2;
}

.delivered {
    background-color: green;
}

.not-returnable {
    background-color: red;
}


.acc-header > button > button {
    position: absolute;
    right: 60px;
}

@media screen and (max-width: 600px) {
    .order-menu {
        display: block;
    }
    .order-img {
        height: 100px;
    }

    .status-wrapper {
        width: 100%;
    }

    .acc-header > button > button {
        position: absolute;
        right: 45px;
    }

    .acc-header > button > span {
        position: absolute;
        left: 10px;
    }
}
