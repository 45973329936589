.group{
    background: white;
    margin-bottom: 15px;
    box-shadow: 0.5px 0px 5px lightgray;
}


@media only screen and (max-width: 600px) {
    

    .group__item {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .group__title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        /* font-weight: bold; */
        padding: 7px;
        /* background-color: #dfd6d6; */
        background-color: white;
        color: black;
        overflow: hidden;
    }

    .group__title>h2 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        font-size: 20px;
    }

    .link{
        color: #05bcc4;
        font-size: 17px;
        text-decoration: none;
    }
    .cat__group__component{
        width: 150px;
    }


}
@media only screen and (min-width: 600px) {
    
    .group__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        padding: 7px;
        /* background-color: #dfd6d6; */
        background-color: white;
        color: black;
    }
    .link{
        color: blue;
        text-decoration: none;
    }
    .link:hover {
        color: #05bcc4;
        text-decoration: underline;
    }
    .group__item{
        padding-left: -2px;
    }

    .cat__group__component{
        width: 300px;
    }
}
