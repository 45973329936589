.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 2px 5px lightgray;
}

.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.2), 0px 2px 5px 1px rgba(0, 0, 0, 0.016), 0px 2px 5px 1px rgba(0, 0, 0, 0.022);
    background-color: #121212;
}

.MuiListItemIcon-root:hover {
    cursor: pointer;
}