.sh_address_form {
    border: 1px solid lightgray;
    padding-bottom: 8px;
    background-color: #ffffff !important;

}

.address_form {
    border-top: 1px solid lightgray;
    padding-top: 10px;
}

.address_form>.row>div {
    padding-left: 0px;
    padding-right: 6px;
}

.form-floating {
    height: 40px;
    background-color: lightgray !important;
    border-radius: 8px;
}

.form-floating>input {
    min-height: 0 !important;
    height: 40px !important;
}
.form-floating:focus-visible {
    outline: none !important;
    border: 0 !important;
    box-sizing: content-box !important;
}

.form-floating>label, .form-floating>input {
    height: 100% !important;
    vertical-align: text-bottom !important;
}

.form-floating>select {
    min-height: 0 !important;
    height: 45px !important;
    padding: 4px !important;
    border: 1px solid lightgray;
    font-size: 16px;
}


.form-floating>label {
    padding: 8px !important;
    font-size: 14px;
    height: 100%;
    z-index: 0 !important;
}



.form-floating>.select_font{
    font-size: 14px;
}


.form-floating > .form-control:not(:placeholder-shown) ~ label::after, 
.form-floating>.form-control:not(:placeholder-shown)~la bel::after,
.form-floating>.form-select~label::after {
    background-color: inherit !important;
}
.cus__name>p {
    font-size: 14px;
    line-height: 19px;
}


.sh_address_complete {
    border: 1px solid lightgray;
}

.form-floating>select>option{
    appearance: auto !important;
    -webkit-appearance: none !important;
    background-color: white !important;
    color: black !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .form-floating>select>option{
        appearance: auto !important;
        -webkit-appearance: none !important;
        background-color: white !important;
        color: black !important;
    }
}