

@media only screen and (max-width: 600px) {
.checkout{
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    padding-bottom: 12px;
    /* background-color: #fefbfb; */
    height: max-content;
    border-radius: 0 !important;
}


.checkout__left{
    background-color: white;
    border: none;
    border-radius: 5px;
    /* box-shadow: 2px 1px 1px #d3d3d3cb; */
}

.checkout__title{
    margin-right: 10px;
    padding: 10px;
    /* border-bottom: 1px solid lightgray; */
    font-weight: bold;
}
      
.checkout__ad{
    width: 63vw;
    height: 100px;
    margin-bottom: 10px;
}
.checkoutbtn{
    /* position: fixed;
    bottom: 10px; */
    /* background: #f0c14b; */
    background-color: #24eac3;
    border-radius: 5px; 
    font-size: 20;
    font-weight: bold;
    padding: 10px;
    width: 96%;
    border: none;
    color: white;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.checkout__actions{
    display: flex;
    align-items: center;
    padding: 15px;
    padding-top: 4px;
    justify-content: space-around;
    background-color: #fefbfb !important;
}
.ch_btn_con {
    background-color: #fefbfb !important;
}
.callBtn{
    border: 1px solid #24eac3;
    border-radius: 5px;
    font-size: 20;
    padding: 10px;
    color: #24eac3;
}

.callBtn>a {
    color: #24eac3;
    outline: none;
}

}

@media only screen and (max-width: 770px) {
    .checkout {
        display: grid !important;
    }

    .checkout__left {
        width: 100% !important;
        margin-bottom: 12px !important;

    }
/* 
    .cart_login, .nocart_contn_shop {
        width: 50% ;
    } */

}




@media only screen and (min-width: 600px) {
    .checkout{
        display: flex;
        padding-right: 30px;
        /* background-color: rgb(248, 244, 244); */
        height: max-content;
        margin-bottom: 12px;
        margin-top: 12px;
    }

    .checkout__left{
        width: 75%;
        /* background-color: whi    te; */
        border: none;
        border-radius: 5px;
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    }
    
    .checkout__title{
        /* margin-right: 10px; */
        padding: 10px;
        background-color: white;
        /* border-bottom: 1px solid lightgray; */
    }
          
    .checkout__ad{
        width: 73vw;
        height: 100px;
        margin-bottom: 10px;
    }

    .checkout__actions{
        display: none;
    }
    
    .checkoutbtn{
        background-color: #24eac3;
        /* background: #f0c14b; */
        border-radius: 5px;
        font-size: 20;
        font-weight: bold;
        padding: 10px;
        width: 400px;
        border: none;
        color: black;
    }
    .callBtn{
        border: 1px solid #24eac3;
        border-radius: 5px;
        font-size: 20;
        padding: 10px;
        color: #24eac3;
    }
    .cart_login, .nocart_contn_shop {
        width: 20% ;
    }
}


.nocart_contn_shop {
    border: 1px solid #43d8ff;
    text-align: center;
}

.nocart_contn_shop:hover {
    background-color: #d3d3d328;
}

.checkout_con {
    padding: 0;
}