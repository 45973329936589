.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}
.footer {
  background: #061a35;
  /* background-color: #031d1f; */
  color: white;
  position: static;
}
.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.grid-row {
  display: flex;
}
.grid-item {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  /* Add more styling as needed */
}
