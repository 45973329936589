.checkoutProduct{
    /* display: flex; */
    margin-top: 20px;
    margin-bottom: 4px;
}


.checkoutProduct__image{
    object-fit: contain;
    width: 100px;
    height: 100px;
}

.checkoutProduct__info{
    padding-left: 20px;
    padding-top: 20px;
}

.checkoutProduct__title{
    font-size: 16px;
    font-weight: bold;
    color: rgb(88, 86, 86);
    text-transform: lowercase !important;
}

.checkoutProduct__rating{
    display: flex;
}

.checkoutProduct__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.removeBtn {
    background: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    /* padding-left: 0; */
    text-align: start;
    font-size: 20;
    font-weight: bold;
    margin-top: 10px;
    /* color: #f0c14b; */
    width: fit-content; 
    color: red;
}

@media screen and (max-width:600px) {
    .qty_cart {
        text-align: start;
    }

    .checkoutProduct__price {
        align-items: flex-start;
    }
}

.pro_chek_body {
text-align: start !important;
}