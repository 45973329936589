@media only screen and (max-width: 770px) {
    .subtotal {
        /* padding-left: 0 !important;
        padding-right: 0 !important; */
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }

    .checkoutbtn {
        align-self: end !important;
    }
}


@media only screen and (min-width: 600px) {
.subtotal{
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #ffffff;
    border: 1px solid lightgray;
    /* box-shadow: 1px 3px 1px #d3d3d3cb; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.subtotal__title{
    font-size: 18px;
    color: #484444;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.subtotal__price{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid lightgray;
}

.subtotal__gift{
    display: flex;
    align-items: center;
}

.subtotal__gift > input {
    margin-right: 5px;
}

.checkoutbtn{
    width: 400px;
    padding: 10px;
    margin: 10px;
    background: #f0c14b;
    border-radius: 5px;
    font-size: 20;
    font-weight: bold;
    padding: 5px;
    border: none;
    color: white;
}
}
@media only screen and (max-width: 600px) {
    /* .subtotal{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30vw;
        height: 100px;
        padding: 10;
        background-color: #f3f3f3;
        border: 1px solid #dddddd;
        border-radius: 3px;
    } */

    .subtotal{
        margin-top: 4px;
        margin-bottom: 10px;
        padding: 10px;
        background-color: white;
        border: 1px solid lightgray  ;
        border-radius: 4px;
        /* box-shadow: 1px 3px 1px #d3d3d3cb; */
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    }

    .subtotal__title {
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;

    }

    .subtotal__price{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid lightgray;
        /* border-bottom: 1px solid lightgray; */
    }
    
    .subtotal__gift{
        display: flex;
        align-items: center;
    }
    
    .subtotal__gift > input {
        margin-right: 5px;
    }
    
    .subtotal > button {
        background: #f0c14b;
        border-radius: 2px;
        width: 100%;
        border: none;
        color: #111;
    }

    .checkout__actions__sub{
        display: none;
    }
}