
@media (max-width: 600px){
    .category_component{
        background: white;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        background-color: white;
        color: black;
    }

    .info>h2 {
        margin: 0;
    }

    .category_component>div>div {
        font-size: 14px;
        font-weight: bold;
    }
}

  @media (min-width: 600px){
    .category_component{
        background: white;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        /* background: rgb(37, 37, 91); */
        /* color: #43d8ff; */
        background-color: white;
        color: black;
        /* box-shadow: 0px 10px 5px lightgray; */
    }

    .info>h2 {
        margin: 0;
    }
    
    /* .title_info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        background: rgb(37, 37, 91);
        color: #43d8ff;
    }
    
    .title_info_red{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        background: rgb(37, 37, 91);
        color: #43d8ff;
    } */
    .carousel-inner{
        display: flex !important;
    }
    .carousel-item{
        display: block !important;
        margin: 0 !important;
        flex: 0 0 calc(100%/4) !important;
    }
/* 
    .cproduct_item{
        box-shadow: 5px 5px 15px rgb(177, 162, 162);
    } */

    .cproduct_item>.product {
        margin: 0;
        padding: 0;
    }
    .cproduct_item:hover{
        /* background-color: rgb(195, 194, 193); */
        -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s forwards;
        animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s forwards;
    }

    

    /* .title_info_red{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background: rgb(37, 37, 91);
        color: white;
    } */

    @-webkit-keyframes scale-in-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes scale-in-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      
}





/* .react-multi-carousel-track>.react-multi-carousel-item--active:nth-last-child(1) {
    padding-left: 5px !important;
    padding-right: 5px !important;
} */

/* .c_home_row {
    height: 100% !important;
} */