/* .product_descrp {
    height: 100px;
    margin-bottom: 12px;
    overflow-x: hidden;
}


.descrp_nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
} */

.product__details__images, .product__details__specs, .product__details__purchase {
    border-radius: 0 !important;
}

.product__details__images {
    position: relative;
}

@media only screen and (min-width: 600px) {
    /* .product__details {
        padding-top: 12px;
    } */

  .product__details__images>img{
        min-width: 80%;
        max-width: 90%;
    }

    a{
        text-decoration: none;
    }
    
    .product_descrp {
        font-family: sans-serif !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .price__column{
        display: block;
        margin: 15px;
        padding: 15px;
        border: 1px solid  rgb(201, 218, 201);
        border-radius: 5px;
    }
    
    .danger{
        color: green;
    }

    .in_stock {
        font-size: small;
        color: #00800088;
    }

    .cart_qty_container {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .cart_qty_form{
        width: 50%;
        height: 50px
    }

    .cart_qty_container>small {
        color: black;
        font-size: large;
        height: 100%;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        height: 100%;
        width: 20%;
        text-align: center;
    }

    .cart_qty_container>small:hover {
        box-shadow: 2px 1px 3px indigo;
        cursor: pointer;
    }

    .cart_qty_decrease {
        padding-right: 11px;
        padding-left: 11px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #6ae8ff;
    }

    .cart_qty_increase {
        padding-right: 11px;
        padding-left: 11px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #6ae8ff;
    }

    .cart_qty_input {
        outline: none;
        width: 100%;
        text-align: center;
        padding: 0;
        border: none;
        height: 100%;
        vertical-align: middle;

    }
    
    .add__cat{
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 30px;
        background-color: #84704b;
        /* background-color:#2f72f7; */
        border-color: #24eac3;;
        color: white;
    }

    .view__cat {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 30px;
        /* background-color: #3efdfdf4; */
        background-color: #5ab5b0;
        border-color: #24eac3;
        color: white;
    }

    .buy__now {
        width: 100%;
        height: 45px;
        font-size: 18px;
        border: none;
        border-radius: 30px;
        background-color:#004870;
        /* background-color: burlywood; */
        border-color: #43d8ff;;
        color: white;
    }

    .addlist{
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: rgb(221, 221, 221);
    }

    .prod_title {
        letter-spacing: -1px !important;
        word-spacing: -1px;
    }

    .product__details__all{
        background: white;
    }

    .spec-name {
        width: 200px;
    }
}
 @media only screen and (max-width: 600px) {
    .product__details{
        padding-left: 20px;
        padding-right: 20px;
    }
    .product_descrp {
        font-family: sans-serif !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .product__details__images{
        background: white;
        border-radius: 10px;
    }

    .product__details__images img{
        min-width: 80%;
        max-width: 90%;
    }

    .product__details__purchase{
        background-color: white;
        border-radius: 10px;
        padding-bottom: 15px;
        /* margin-left: -12px;
        margin-right: -10px; */
        margin-bottom: 10px;
    }

    .purchase__buttons{
        text-align: center;
    }
    /* .product__details__specs__others {
        text-align: center !important;
    } */

    .product__details__specs{
        border-radius: 10px;
        background: white;
        margin-bottom: 10px;
    }
    
    .product__details__images img{
        min-width: 80%;
    }
    
    .price__column{
        display: block;
        height: 65%;
        border: none;
        /* background-color: white; */
    }
     .purchase__buttons{
        display: block;
    } 
    
    .danger{
        color: green;
    }

    .cart_qty_container {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        height: 100%;
        width: 100%;
        align-items: center;
    }

    .cart_qty_form{
        width: 80%;
        height: 50px
    }

    .cart_qty_container>small {
        color: black;
        font-size: large;
        height: 100%;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        height: 100%;
        width: 20%;
        text-align: center;
    }

    .cart_qty_container>small:hover {
        box-shadow: 2px 1px 3px indigo;
        cursor: pointer;
    }

    .cart_qty_decrease {
        padding-right: 11px;
        padding-left: 11px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #6ae8ff;
    }

    .cart_qty_increase {
        padding-right: 11px;
        padding-left: 11px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #6ae8ff;
    }

    .cart_qty_input {
        outline: none;
        width: 100%;
        text-align: center;
        padding: 0;
        border: none;
        height: 100%;
        vertical-align: middle;

    }

    .in_stock {
        font-size: small;
        color: #00800088;
    }

    
    
    .add__cat{
        width: 90%;
        height: 45px;
        font-size: 18px;
        border: none;
        border-radius: 30px;
        background-color: #84704b;
        /* background-color: #24eac3; */
        border-color: #24eac3;
        color: white;
    }

    .view__cat {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 30px;
        /* background-color: #3efdfdf4; */
        background-color:#5ab5b0;
        border-color: #24eac3;;
        color: white;
    }

    .buy__now {
        width: 90%;
        height: 45px;
        font-size: 18px;
        border: none;
        border-radius: 30px;
        background-color: #004870;
        border-color: #43d8ff;;
        color: white;
    }

    .addlist{
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: rgb(221, 221, 221);
    }

    .product__details__all{
        display: block;
        /* background-color: white; */
    }
    .prod_title {
        letter-spacing: -1px !important;
        font-size: 27px !important;
    }

} 



.region, .city, .region>select, .city>select {
    width: 100%;
}

.region, .city {
    margin-bottom: 12px;
}

.region>select, .city>select {
    outline: none;
    padding: 10px;
    border-radius: 5px;
}

.region>select:hover, .city>select:hover {
    cursor: pointer
};


.cas_p {    
   font-family: sans-serif !important;;
}



.s-info {
    border: 1px solid lightgray;
}