
.cart__pop__container {
    position: relative;
}

.cart__pop, .account__pop {
    position: absolute;
    right: 0;
    top: 3rem;
    z-index: 3;
    padding: 5px;
    width: 300px;
    background-color: white;
    box-shadow: 0px 0px 2px #0dcaf0;
    visibility: hidden;
}   
.cart__pop:hover {
    visibility: visible;
}
.cart__pop>.cart__pop__body {
    height: 250px;
    max-height: 70%;
    overflow-y: scroll;
    /* background-color: rgb(13, 202, 240); */
    padding-top: 10px;
}

.cart__pop__product__container {
    display: flex;
    width: 100%;
    height: 70px;
    column-gap: 5px;
    /* height: 85.8%; */
    /* overflow-y: scroll; */
    /* padding: 4px;
    margin-bottom: 1px; */
}

.cart__pop_img_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 25%;
    /* text-align: center; */

}

.cart__pop_img_container>img {
    text-align: center;
    /* width: 100%; */
}

.cart__pop_img_container>div {
    position: relative;
    color: red;
    height: fit-content;
    left: 0;
    margin-top: -12px;
    /* margin-left: 2px; */
    /* background-color: red; */

}

/* .cart__pop_img_container>div>span {
    background-color: red;
} */

.cart__pop_img_container>div:hover {
    cursor: pointer;
}

.popup_product_name>h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

/* .popup_product_name>h3:hover {
    cursor: pointer;
    color: rgba(0, 0, 255, 0.807);
} */

.cart_pop_details {
    width: 80%;
}

.cart_pop_view_cart {
    background-color: #5ab5b0;
}
.cart_pop_checkout {
    background-color: #24eac3;
}
.cart__pop__footer {
    position: static;
    display: flex;
    justify-content: space-between;
    height: 15%;
    border-top: 1px solid lightgray;
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
    bottom: 0;
    /* padding-bottom: 10px; */
    /* background-color: rgb(13, 202, 240); */
}

.cart__pop__footer>a, .cart__pop__footer>button {
    border: 0;
    padding: 7px;
    border-radius: 4px;
    width: 48%;
    text-align: center;
    text-decoration: none;

}  

/* .cart_pop_checkout {
    background-color: green;
    color: white;
} */

.price_quantity {
    display: flex;
}

.price_quantity>p {
    font-size: 14px;
}
