@media only screen and (min-width:600px) {
    .sub_category_heading_con>h5 {
        font-size: 17px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }
    .sub_cat_form {
        background-color: white;
    }
    .content_ {
        margin-top: 5px;
        margin-bottom: 5px;
        /* display: flex; */
        align-items: center;
        padding: 5px 5px 5px 8px;
        overflow-x: hidden;

    }
    .content_>input {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #555555;
        /* border-radius: 50%; */
        background-clip: content-box;
        padding: 3px;
        /* accent-color: #FFC107; */
        margin-right: 5px;
        
    }

    .content_>label:hover, .content_>input:hover {
        cursor: pointer;
    }
    .content_>input[type='checkbox']{
        display: grid;
        place-content: center;
    }

    .content_>input[type='checkbox']::before{
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #FFC107;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        border: 2px solid #FFC107;
        
    }

    .content_>input[type="checkbox"]:checked::before {
        transform: scale(1);
        background-color: #FFC107;
        accent-color: #FFC107;
      }

      .content_>input[type="checkbox"]:disabled {
        color: lightgray;
        accent-color: lightgray;

      }

    .content_>label {
        width: 90%;
        font-size: 14px;
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    
}



@media only screen and (max-width:600px) {
    .content_ {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
    }

    .content_>input {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #555555;
        /* border-radius: 50%; */
        background-clip: content-box;
        padding: 3px;
        /* accent-color: #FFC107; */
        margin-right: 5px;
        
    }

    .content_>label:hover, .content_>input:hover {
        cursor: pointer;
    }
    .content_>input[type='checkbox']{
        display: grid;
        place-content: center;
    }

    .content_>input[type='checkbox']::before{
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #FFC107;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        border: 2px solid #FFC107;
        
    }

    .content_>input[type="checkbox"]:checked::before {
        transform: scale(1);
        background-color: #FFC107;
        accent-color: #FFC107;
      }

    .content_>label {
        width: 90%;
        padding-left: 5px;
        font-weight: normal;
    }
}