/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap'); */
.header_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #061a35 !important;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 4px;
}

.headers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 0;
  /* background-color: #f2f2f2; */
  background-color: #061a35 !important;
}

.logo {
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  width: 8%;
}

.logo > div {
  width: 100%;
  height: 50px;
  display: flex;
}

.logo > div > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_img {
  width: 100%;
  height: 100px;
  padding: 8px;
  object-fit: contain;
}

.search-bar {
  position: relative;
  min-width: 200px;
  flex: 1;
}

.search-bar > div {
  background-color: white;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.search-bar > div > span {
  width: 40px;
  cursor: pointer;
}

.search-bg {
  position: absolute;
  background-color: #000000bc;
  min-width: 100%;
  min-height: 100%;
}

.header__searchIcon {
  padding: 6px;
  /* height: 100% !important; */
  /* background-color: #24eac3; */
  background-color: #05bcc4;
  border-top-left-radius: -5px;
  border-bottom-left-radius: -5px;
  width: 100% !important;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 3 !important;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f2f2f2;
}

.nav_links {
  background-color: white;
  /* border: 0; */
  /* padding-bottom: 12px; */
}

.nav_links > div {
  padding: 0;
  border: 0;
}

.nav_links > div > ul {
  display: flex;
  list-style: none;
  overflow-x: scroll;
  padding: 0;
  margin: 0;
}

.nav_links > div > ul::-webkit-scrollbar {
  display: none;
}

.links {
  color: black !important;
}

.links:hover {
  color: #05bcc4 !important;
}

.header__optionBasket {
  position: relative;
  right: 3px;
}
.header__link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.header__link:hover {
  color: blue;
}

.header__basketCount {
  position: relative;
  right: 3px;
  /* top: 5px; */
  top: -20px;
}

.Logout {
  background-color: #041123;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}
/* Responsive styles */

@media screen and (min-width: 600px) {
  .header__nav {
    display: flex;
    color: #eee7e7;
  }

  .header__optionBasket {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .header__basketCount {
    font-size: 13px;
    font-weight: 800;
  }

  .header__option {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
  }
  .account-drop:hover {
    cursor: pointer;
    color: blue;
  }
}

@media screen and (max-width: 768px) {
  .headers {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .header__nav {
    justify-content: flex-end;
    width: fit-content;
    align-items: center;
  }

  .logo {
    justify-content: space-between;
    /* margin-bottom: 10px; */
    width: 100%;
    font-size: 1.2rem;
    height: 30px;
    height: 50px;
  }
  .logo > div {
    /* width: 100%; */
    height: 100%;
    display: flex;
  }

  .logo > div > a {
    display: block;
  }

  .logo_img {
    width: 90px;
    height: 70px;
    padding: 0px;
    margin-top: -10px;
  }
  .search-bar {
    width: 100%;
    /* flex: 1; */
  }

  .search-bar input {
    width: 100%;
    padding: 5px;
  }

  .links {
    overflow-x: hidden;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
  }

  .links > span {
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .nav_links {
    top: 6.8rem;
  }

  .nav_links > div > ul {
    display: flex;
    list-style: none;
    overflow-x: scroll;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .header__link {
    width: fit-content;
  }
}

@media screen and (max-width: 480px) {
  .headers {
    padding: 8px;
  }
  .logo {
    font-size: 1.2rem;
  }
  .header__link {
    width: 44px;
  }
}

@media screen and (max-width: 300px) {
  .headers {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.v-focus {
  background-color: #000000bc;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 3rem;
}



.cas-sell-cen {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #05bcc4;
  padding: 12px;
  margin-bottom: 12px;
}

.cas-sell-cen > h1 {
  font-weight: bold;
  font-size: x-large;
}

.cs-hover {
  color: #000000d8;
}

.cs-hover:hover {
  color: #05bcc4 !important;
}