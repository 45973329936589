@media only screen and (min-width: 600px) {
  .wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-height: 80vh;
    width: 100%;
  }

  .logo_ {
    width: 160px;
    object-fit: contain;
    /* margin: 0 20px;
    padding: 10px; */
  }

  .user_auth_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 3px 20px #d3d3d381;
    padding: 10px;
    margin: 0 !important;
  }

  .signup_heading_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .signup_heading {
    font-size: xx-large;
    font-weight: bold;
    text-align: center;
  }

  .signup_text {
    font-size: medium;
    align-self: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.61);
    border-bottom: 3px solid indigo;
  }

  .form form {
    margin: 20px 0;
  }

  form > div > label {
    font-weight: bold;
  }

  .form form .login {
    width: 375px;
  }

  .form form .field label {
    margin-bottom: 2px;
  }

  .form form .field input {
    outline: none;
  }

  .form form .input input {
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .form form .button input {
    margin-top: 13px;
    height: 45px;
    border: none;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }

  .submitbtn {
    width: 375px;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
  }
  .field {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .auth_form_wrapper {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .auth_form_wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  input {
    width: 250px;
  }
  .signup_heading_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .signup_heading {
    font-size: xx-large;
    font-weight: bold;
    text-align: center;
  }

  .signup_text {
    font-size: medium;
    align-self: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.61);
    border-bottom: 3px solid indigo;
  }

  form > div > label {
    font-weight: bold;
  }

  .submitbtn {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
  }
}


.login-btn {
    background-color: #05BCC4;
    padding: 8px;
    font-size: large;
    font-weight: bold;
    width: 100%;
    border: none;
    border-radius: 2px;
}

.login-btn:hover {
    background-color: #059aa1;
    color: white;
}