.feedback {
    position: fixed;
    bottom: 1.5rem;
    right: 2rem;
    z-index: 3;
}

.feedback_form::-webkit-scrollbar {
    display: none;
}

.feedback_form {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: scroll;
}

.form_close_btn {
  position: absolute;
  right: 5px;
}

.form_close_btn:hover {
  cursor: pointer;
  color: red !important;
}

.feedback_form>form {
    height: 100%;
}

.feedback_icon {
  background-color: #f28f0f;
    color: white;
    padding: 20px;
    border-radius: 40px;    
}

.feedback_icon:hover {
    cursor: pointer;
    -webkit-animation: jello-horizontal 0.9s both;
    animation: jello-horizontal 0.9s both;
}

.feedback_icon {
  position: absolute;
  bottom: 0rem;
  /* width: 16rem; */
  right: 0;
  color: white;
  background-color: #f28f0f;
}

.comment{
  position: absolute;
  right: 4rem;
  bottom: 1rem;
  font-size: 12px;
  width: fit-content;
  margin: 0;
  padding: 4px;
  background-color: white;
}

.comment_close_btn {
  position: absolute;
  top: -0.6rem;
  left: -0.6rem;
  background-color: white;
  border-radius: 30px;
  color: red;
}

.comment_close_btn:hover {
  cursor: pointer;
}

.comment>p {
  margin: 0;
  width: 12rem;
}

.feedback_form {
    position: fixed;
    bottom: 5rem;
    right: 4rem;
    width: 40%;
    height: 50%;
    background-color: indianred;
}

.form_group {
    padding: 10px;
}

/* .form_group>.feedback_name {
    height: 20%;
} */

.form_group>input {
    padding: 5px;
    width: 100%;
    border: 0;
    outline: none;
}

.form_group>textarea {
    border: 0;
    outline: none;
    height: 200px;
    resize: none;
    padding: 5px;
}

.feedback_submit_btn {
    align-self: flex-end;
    margin-right: 8px;
    outline: none;
    border: 0;
    padding: 5px;
    width: 30%;
    background-color: green;
    color: white;

}



@media screen and (max-width:600px) {
  .feedback {
    right: 1rem;
    bottom: 1rem;
  }
  
  .feedback_form {
    width: 70%;
    right: 1rem;
    bottom: 4rem;
  }
  
  .feedback_icon {
    padding: 5px;
    border-radius: 0;
  }

  .feedback_icon>svg {
    width: 2rem;
    height: 2rem;
    /* border-radius: 10px; */
  }

  .feedback_icon>div {
    bottom: 0;
    right: 2.7rem;
    width: 15.6rem;
  }

  .comment {
    right: 3rem;
    bottom: 0;
  }
  
}

@media screen and (min-width:600px) {
  .feedback_form {
    width: 40%;
  }
}

@media screen and (min-width:1024px) {
  .feedback_form {
    width: 25%;
  }
}

@-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }

.feedback_p {
  z-index: 3;
  position: fixed;
  bottom: 4.8rem;
  right: 2rem;
  display: flex;
  background-color: white;
  padding: 5px;
  /* border-radius: 10px;  */
}

.feedback_upd {
  z-index: 3;
  position: fixed;
  bottom: 0.5rem;
  right: 2rem;
  display: flex;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}


.tup {
  margin-right: 1rem;
  /* font-size: 19px !important; */
  background-color: #24eac3;
  padding: 10px;
  border-radius: 10px;
}

.tup:hover {
  cursor: pointer;
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
  color: green !important;
}

.tdown {
  background-color: #6ae8ff;
  padding: 10px;
  border-radius: 10px;
}

.tdown:hover {
  cursor: pointer;
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
  color: red !important;

}

@media screen and (max-width:600px) {
  .feedback_upd {
    right: 1rem;
  }
}


@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
