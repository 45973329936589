
.sell {
  width: 100%;
  background-color: #d3d3d394;
  text-align: center;
}

.sell > a {
  color: black;
}

.sell > a:hover {
  color: #05bcc4;
}

.cate_ren {
  column-gap: 10px;
}

.home_banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35%;
  background: linear-gradient(to bottom, #ffffff00, #ffffff);
  pointer-events: none;
}

@media only screen and (min-width: 600px) {
  .cas_card {
    height: 394px;
    background-color: white;
    box-shadow: 2px 0px 5px lightgray;
  }
  .cas_card > h2 {
    margin: 0 8px;
  }
  .home__row {
    display: flex;
    height: 355px;
    background-color: white;
    box-shadow: 0.5px 0px 5px lightgray;
  }
  .home__image {
    width: 100%;
    image-rendering: optimizeQuality;
    height: 100%;
  }
  .cas_card_row {
    display: flex;
    height: 355px;
  }

  .chome__row {
    display: flex;
  }

  .product_item {
    margin: 5px;
  }

  .home_banner {
    object-fit: contain;
    z-index: 0 !important;
    position: relative;
    display: inline-block;
  }

  .pushup {
    margin-top: -255px;
  }

  .cas_banner {
    height: 250px;
  }
  .cas_banner > img {
    height: 100%;
    width: 100%;
  }

  .rec_banner {
    height: 250px;
    height: 250px;
  }

  .rec_banner > img {
    width: 100%;
    /* max-height: 250px; */
    /* min-height: 200px; */
    height: 100%;
    /* object-fit:contain; */
  }

  .cat__type_c {
    box-shadow: 0.5px 0px 5px lightgray;
}



  .cas_banner {
    height: 250px !important;
  }
  .cas_banner > img {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 1223px) {
    .pushup {
        margin-top: -220px;
    }
}

@media screen and (max-width: 1024px) {
    .pushup {
        margin-top: -150px;
    }
}
@media screen and (max-width: 900px) {
    .pushup {
        margin-top: -110px;
    }
}
@media screen and (max-width: 700px) {
    .pushup {
        margin-top: -120px;
    }
}
@media only screen and (max-width: 600px) {
    
    
  .home__row, .cas_card_row {
    display: grid;
    grid-template-columns: 49.9% 49.9%;
    gap: 1px;
    box-shadow: 0.5px 0px 5px lightgray;
  }

  .cat__type {
    margin-top: 5px;
    margin-bottom: 12px;
  }

  .cat__type_c {
    margin-top: 5px;
    margin-bottom: 12px;
    box-shadow: 0.5px 0px 5px lightgray;
  }

  .product_item {
    margin: 0px 5px;
  }

  .home_banner {
    height: 140px;
  }

  .cas_banner {
    height: fit-content;
  }
  .cas_banner > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .home_banner > img {
    height: 100% !important;
    image-rendering: optimizeQuality;
    aspect-ratio: 4/1 !important;
    object-fit: fill;
  }

    
    .rec_banner > img{
        width: 100%;
        height: 100%;
        margin-top: 5px;
    }



  .pushup {
    margin-top: -30px;
  }
}
